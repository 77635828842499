import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["notes"];

  connect() {
    this.element.innerHTML = `<div class="loader"> </div>`

    this.checkAndLoad = this.checkAndLoad.bind(this);
    this.closeExisting = this.closeExisting.bind(this);
    this.makeCall = this.makeCall.bind(this);

    document.addEventListener("c2c:init", this.checkAndLoad, false);
  }

  checkAndLoad() {
    fetch(`/outgoing_call_details/check_status_and_load/${this.data.get("type")}/${this.data.get("id")}`)
      .then((response) => response.text())
      .then((html) => {
        this.element.innerHTML = html;
      });
  }

  closeExisting(e) {
    e.preventDefault();

    const existingCallId = e.target.dataset.existingCallId
    const note = this.notesTarget.value;

    this.element.innerHTML = `<div class="loader"> </div>`

    fetch(`/outgoing_call_details/${existingCallId}.json`, {
      method: "put",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ 'status': 'completed', 'note': note }),
    }).then(r => r.json()).then(r => this.checkAndLoad());
  }

  makeCall(e) {
    e.preventDefault();
    const objectId = this.data.get("id");
    const objectType = this.data.get("type")

    this.element.innerHTML = `<div class="loader"> </div>`

    fetch("/outgoing_call_details.json", {
      method: "post",
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ objectType, objectId}),
    }).then(r => r.json()).then(r => this.checkAndLoad());
  }
}
