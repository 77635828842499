import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.load();

        $(document).on('content.reload', function(event, callback) {
            this.load()
        }.bind(this))
    }

    load() {
        fetch(this.data.get("url"))
            .then(response => response.text())
            .then(html => {
                this.element.innerHTML = html
            })
    }
}