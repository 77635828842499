import Bloodhound from './lib/typeahead'

var diagnoses = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        url: '/kyc_diagnoses/autocomplete_diagnosis_name/%QUERY',
        wildcard: '%QUERY',
        cache: false
    }
});
$(document).on("turbo:load", function(){
    if ( $('#kyc_diagnosis_diagnosis_name').val() ) {
      $('.input-field.kyc_diagnosis_diagnosis_name > label').addClass('active');
    }
    $('#kyc_diagnosis_diagnosis_name').typeahead({
      minLength: 3
    },
    {
      name: 'diagnoses',
      limit: 15,
      source: diagnoses.ttAdapter()
    }).on('typeahead:active', function(ev,value){
        $('.input-field.kyc_diagnosis_diagnosis_name > label').addClass('active');
    });

    $('#chronic_condition_action_event').on('cocoon:after-insert', function() {
      check_to_hide_or_show_add_link();
      $('select').material_select();
    }).on("cocoon:after-remove", function() {
      check_to_hide_or_show_add_link();
    });

    check_to_hide_or_show_add_link();

    function check_to_hide_or_show_add_link() {
      if ($('#chronic_condition_action_event .nested-fields').length == 1) {
        $('#chronic_condition_action_event a.add_fields').hide();
      } else {
        $('#chronic_condition_action_event a.add_fields').show();
      }
    }

    $(".boolean.optional").on("change", function() {
      if (this.parentElement.parentElement.id == 'status' && this.checked)
      {
        var box = confirm("This might be deactivated by a sales / PCO as per the patient's request. Are you sure you want to reactivate the re engagement recommendation?");
        (box == true) ? true : this.checked = false
      }
    });

    check_to_hide_or_show_re_engagement_recommendation();

    function check_to_hide_or_show_re_engagement_recommendation()
    {
      // show if re_engagement_recommendation service_type is Physiotherapy
      if ($('#kyc_diagnosis_service_type').val() == 1) {
        $('#re_engagement_recommendation').show();
      } else {
        $('#re_engagement_recommendation').hide();
      }
    }

    $("#kyc_diagnosis_service_type").change(function() {
      var service_type_id = $(this).val();
      var patient_id = $("#kyc_diagnosis_patient_id").val();

      check_to_hide_or_show_re_engagement_recommendation();

      $.ajax({
        url: "/kyc_diagnoses/appointment_list_for_service_type",
        method: "GET",
        dataType: "json",
        data: {service_type_id: service_type_id, patient_id: patient_id},
        success: function (response) {
          $("#kyc_diagnosis_diaganosable_id").empty();
          $("#kyc_diagnosis_diaganosable_id").append('<option value="">Select Appointment</option>');
          if (response.length > 0) {
            for(var i=0; i< response.length; i++){
              $("#kyc_diagnosis_diaganosable_id").append('<option value="' + response[i][0] + '">' + response[i][1] + '</option>');
            }
          }
        }
      });
    });

});
