import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="employee-event"
export default class extends Controller {

  static targets = ["submit","comments"];
  connect() {
    this.submitTarget.disabled = true;
    this.commentsTarget.addEventListener("input", () => {
      if (this.commentsTarget.value.trim().length > 0) {
        this.submitTarget.disabled = false;
      } else {
        this.submitTarget.disabled = true;
      }
    });
  }
}
