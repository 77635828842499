(function() {

    $(document).ready(function() {
        $('form#gcl_form').submit(function() {
            if ($('#other_form_state').val() === 'data_processing' && !$('#other_dnd_check').prop('checked')) {
                alert("Please select DND checkbox.");
                return false;
            }
            return true;
        });
        return this.submitInvisibleCaptchaForm = function(variable) {
            return $('form#gcl_form').submit();
        };
    });

}).call(this);
