import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["sap_product_sku", 'quantity'];

    connect() {
        console.log("sap product sku event loaded");

        var sap_product_sku = $(this.sap_product_skuTarget);

        sap_product_sku.sm_select({
            ajax: {
                url: () => sap_product_sku.data('url'),
                dataType: 'json',
                delay: 400,
            }
        });

        var sap_product_sku_id = sap_product_sku.data('product-id');

        if (sap_product_sku_id > 0) {
            var option = new Option(sap_product_sku.data('product-name'), sap_product_sku_id, true, true)
            sap_product_sku.append(option).trigger('change');
        }

        $('select').material_select();

    }

    validate(){
        const errors = [];
        if($(this.quantityTarget).val() > 2){
            errors.push(`Selected ${$(this.quantityTarget).val()} items - ${$(this.sap_product_skuTarget).text()}`)
        }
        return errors;
    }


}
