import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["profession_id", "clinician_specialization_fields", "referrer_type"];

    connect() {
        console.log("Invite user loaded");

        this.handleProfessionTypeChange();
        $(this.profession_idTarget).on('change', (e) => {
            this.handleProfessionTypeChange();
        });
    }

    handleProfessionTypeChange()
    {
        const profession =$(this.profession_idTarget).find('option:selected').data('value');

        const elem = $(this.clinician_specialization_fieldsTarget);
        const referrerType = $(this.referrer_typeTarget);
        if ( profession == 'health_angel') {
            elem.find('input,select').prop('disabled', true);
            elem.hide();
            referrerType.find('input,select').prop('disabled', false);
            referrerType.show();
            referrerType.find('select').material_select();

        }
        else if(profession == 'pg_owner'){
            elem.find('input,select').prop('disabled', false);
            elem.hide();
            referrerType.find('input,select').prop('disabled', true);
            referrerType.hide();
        }else {
            elem.find('input,select').prop('disabled', false);
            elem.show();
            referrerType.find('input,select').prop('disabled', true);
            referrerType.hide();
        }
    }

}
