import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["assignee"];

    static values = {
        professions: Array,
    }

    connect() {
        var assignee = $(this.assigneeTarget);
        
        var urlQueryParams = new URLSearchParams()
        this.professionsValue.forEach(l => urlQueryParams.append('profession[]', l) )

        assignee.sm_select({
            ajax: {
                url: () => `${assignee.data('url')}?${urlQueryParams.toString()}`,
                dataType: 'json',
                delay: 400
            }
        });
        var assignee_id = assignee.data('user-id');

        if (assignee_id > 0) {
            var option = new Option(assignee.data('user-name'), assignee_id, true, true)
            assignee.append(option).trigger('change');
        }

    }

    teardown(){
        $(this.assigneeTarget).select2('destroy');
    }


}
