import moment from 'moment';

(function() {
    window.Util = {};

    Util.MessageHandler = class MessageHandler {
        constructor(messages) {
            this.render = this.render.bind(this);
            this.messages = messages;
        }

        render() {
            var i, len, ref, results, service, slot;
            this.reset();
            ref = this.messages;
            results = [];
            for (i = 0, len = ref.length; i < len; i++) {
                slot = ref[i];
                if (slot['serviceId'] === 68) {
                    service = 'Manipal';
                } else {
                    service = 'Portea';
                }
                results.push($('#' + this.getCityId(slot['areaId']) + ' #' + this.getSlotId(slot['daynumber'], slot['slot'])).append('<div class="chip light-blue lighten-5">' + service + ': ' + slot['count'] + '</div>' + '<br/>'));
            }
            return results;
        }

        reset() {
            $('#updated_time').text(moment().format('MMMM Do YYYY, h:mm:ss a'));
            return $('td.val').html('');
        }

        getSlotId(day, time) {
            return day + '_' + time.replace(":", "_");
        }

        getCityId(city_id) {
            return 'City' + '_' + city_id;
        }

    };

    Util.GenericMessageHandler = class GenericMessageHandler extends Util.MessageHandler {
        constructor(messages) {
            super(messages);
        }

        render() {
            var i, len, ref, results, slot;
            this.reset();
            ref = this.messages;
            results = [];
            for (i = 0, len = ref.length; i < len; i++) {
                slot = ref[i];
                results.push($('#bookings' + ' #' + this.getSlotId(slot['daynumber'], slot['slot'])).append('<div class="chip light-blue lighten-5">' + slot['count'] + '</div>' + '<br/>'));
            }
            return results;
        }

    };

}).call(this);
