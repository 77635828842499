import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "approve",
    "modal",
    "submit",
    "notes",
    "close",
    "paymentDate",
    "paymentDateLabel",
    "transactionId",
    "transactionIdLabel",
    "errors",
  ];

  static values = {
    url: String,
    action: { type: String, default: "" },
  };

  errors = [];

  connect() {
    console.log($(this.modalTarget));
    $(this.modalTarget).modal({ dismissible: false });

    this.payment_date = $(this.paymentDateTarget);
    this.payment_date_label = $(this.paymentDateLabelTarget);
    this.transaction_id = $(this.transactionIdTarget);
    this.transaction_id_label = $(this.transactionIdLabelTarget);
    this.errors = $(this.errorsTarget);

    this.hideAll();

    this.submitTarget.disabled = true;
    this.notesTarget.addEventListener("input", () => {
      if (this.notesTarget.value.trim().length > 0) {
        this.submitTarget.disabled = false;
      } else {
        this.submitTarget.disabled = true;
      }
    });
  }

  setFinalizeAction() {
    this.setAction('finalize', 'Finalize');
  }

  setRecomputeAction() {
    this.setAction('recompute', 'Recompute');
  }

  setIncorrectAction() {
    this.setAction('incorrect', 'Mark Incorrect');
  }

  setSubmitToFinanceAction() {
    this.setAction('submit_to_finance', 'Submit To Finance');
  }

  setRejectAction() {
    this.setAction('reject', 'Reject');
  }

  setVerifyAction() {
    this.setAction('verify', 'Verify');
  }

  setMarkPayAction() {
    this.setAction('pay', 'Mark Paid');
    this.showAll();
  }

  setAction(actionValue, actionText) {
    this.actionValue = actionValue;
    this.submitTarget.innerText = actionText;
    this.clearErrors();
    this.hideAll();
  }

  submit() {
    this.submitTarget.disabled = true;
    this.closeTarget.disabled = true;

    const notes = this.notesTarget.value;
    this.clearErrors();

    const paymentDate = this.payment_date.val();
    const transactionId = this.transaction_id.val();

    if (this.validate(paymentDate, transactionId)) {
      this.showElement(this.errors);
      this.submitTarget.disabled = false;
      this.closeTarget.disabled = false;
      return;
    }

    fetch(`${this.urlValue}?event=${this.actionValue}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: this.getPayload(paymentDate, transactionId, notes),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Unable to process the action, please contact support."
          );
        }
        return response.json();
      })
      .then((data) => {
        this.closeModal();
        location.reload();
      })
      .catch((e) => {
        alert("Error: " + e);
      })
      .finally(() => {
        this.submitTarget.disabled = false;
        this.closeTarget.disabled = false;
      });
  }

  closeModal() {
    $(this.modalTarget).modal("close");
  }

  hideAll() {
    this.hideElements([
      this.payment_date,
      this.transaction_id,
      this.payment_date_label,
      this.transaction_id_label,
    ]);
    this.payment_date_label.removeClass("active");
    this.transaction_id_label.removeClass("active");
  }

  showAll() {
    this.showElements([
      this.payment_date,
      this.transaction_id,
      this.payment_date_label,
      this.transaction_id_label,
    ]);
    this.payment_date_label.addClass("active");
    this.transaction_id_label.addClass("active");
  }

  hideElements(items) {
    items.forEach((option) => {
      this.hideElement(option);
    });
  }

  showElements(items) {
    items.forEach((option) => {
      this.showElement(option);
    });
  }

  hideElement(elem) {
    elem.find("input,select").prop("disabled", true);
    elem.hide();
  }

  showElement(elem) {
    elem.find("input,select").prop("disabled", false);
    elem.find($("select")).material_select();
    elem.show();
  }

  clearErrors() {
    this.errors[0].innerHTML = "";
    this.errors.hide();
  }

  validate(payment_date, transactionId) {
    this.errors.append("Please check below errors");

    let error = false;

    if (this.actionValue == "pay") {
      if (transactionId == "" || transactionId == null) {
        this.errors.append("<li> Transaction Id is mandatory </li>");
        error = true;
      }

      if (
        payment_date == "" ||
        payment_date == null ||
        new Date(payment_date) > new Date()
      ) {
        this.errors.append(
          "<li> Payment date cannot be greater than today </li>"
        );
        error = true;
      }
    }
    return error;
  }

  getPayload(paymentDate, transactionId, notes) {
    return JSON.stringify({
      key_account_payout: {
        payment_date: paymentDate,
        transaction_id: transactionId,
        key_account_payout_notes_attributes: [
          {
            notes,
            action: this.actionValue,
          },
        ],
      },
    });
  }
}
