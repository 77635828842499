import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/flatpickr.css";
import "../common/flatpickr.css";

export default class extends Flatpickr {

    initialize() {
        //global options
        this.config = {
            onReady: function () {
                if (this.monthsDropdownContainer != null) {
                    this.monthsDropdownContainer.classList.add('browser-default')
                }

            }
        };
    }


}

