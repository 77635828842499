import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["action_type", "service_type"];

    connect() {

        console.log("Treatment Schedules Loaded")

        this.handleActionTypeChange();
        $(this.action_typeTarget).on('change', (e) => {
            this.handleActionTypeChange();
        });
    }

    handleActionTypeChange() {

        const actionType = $(this.action_typeTarget).val();
        console.log("Action type has changed to ", actionType);

        if (actionType == 5) {
            $(this.service_typeTarget).show()
        }
        else {
            $(this.service_typeTarget).hide()
        }

    }

}
