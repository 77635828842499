import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs";
// Connects to data-controller="tribute"
export default class extends Controller {

  static values = {
    options: Array
  }
  connect() {

    const tribute = new Tribute({
      values: this.optionsValue
    });

    tribute.attach(document.querySelectorAll('.mentionable'));
  }
}
