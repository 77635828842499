import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ['source', 'bucket', 'professionals', 'add_patient_benefits',
        'patient_benefits', 'duration_type'];

    connect() {

        this.handleSourceChange();
        $(this.sourceTarget).on('change', (e) => {
            this.handleSourceChange();
        });
        this.handlePatientBenefit(true);
        $(this.add_patient_benefitsTarget).on('click', (e) => {
            e.preventDefault();
            this.handlePatientBenefit(false);
        });
    }

    handleSourceChange() {
        const source = $(this.sourceTarget).val();

        if (source == null || source == '') {
            return
        }
        this.updateBucketDetails($(this.bucketTarget), source);
        this.updateHealthProfessionalList($(this.professionalsTarget), source);
    }

    handlePatientBenefit(hide){
        const elem = $(this.patient_benefitsTarget);
        if(hide === true && $(this.duration_typeTarget).val() == ''){
            this.hideFormFields(elem);
        }else{
            this.hideFormFields($(this.add_patient_benefitsTarget));
            this.showFormFields(elem);
        }
    }

    showFormFields(elem) {
        elem.find($('input')).prop('disabled', false);
        elem.find($('select')).prop('disabled', false);
        elem.find($('select')).material_select();
        elem.find($('textarea')).prop('disabled', false);
        elem.show();
    }

    hideFormFields(elem) {
        elem.find($('input')).prop('disabled', true);
        elem.find($('select')).prop('disabled', true);
        elem.find($('textarea')).prop('disabled', true);
        elem.hide();
    }

   updateBucketDetails(bucket_elem, source) {
        fetch('/referrers/buckets/' + source + ".json", {
            method: 'GET',
            credentials: "same-origin"
        }).then(
            (response) => response.json()
        ).then((json) => {
            const selected_value = bucket_elem.val();
            bucket_elem.empty();
            const {bucket_values} = json;
            bucket_elem.append($(new Option('Select', '', false, false)))
            bucket_values.forEach((option) => {
                const selected = option.display_name === selected_value ? true : false
                const o = new Option(option.display_name, option.display_name, selected, selected);
                bucket_elem.append($(o))
            });
            bucket_elem.material_select();
        }).catch((err) => console.log(err)) ;
    }

    updateHealthProfessionalList(professional_elem, source) {
        fetch('/health_professional_details/referrer_professionals/' + source + ".json", {
            method: 'GET',
            credentials: "same-origin"
        }).then(
            (response) => response.json()
        ).then((json) => {
            const selected_value = professional_elem.val();
            professional_elem.empty();
            const {professionals} = json;
            if(professionals.length != 0){
                professionals.forEach((option) => {
                    const selected = selected_value.includes(option.id.toString()) ? true : false
                    const o = new Option(option.name, option.id, selected, selected);
                    professional_elem.append($(o))
                });
            }
            professional_elem.material_select();
        }).catch((err) => console.log(err)) ;
    }
}
