import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["sms_template"];

    connect() {
        console.log("sms template event loaded");

        var sms_template = $(this.sms_templateTarget);

        sms_template.sm_select({
            ajax: {
                url: () => sms_template.data('url'),
                dataType: 'json',
                delay: 400,
            }
        });

        var sms_template_id = sms_template.data('user-id');

        if (sms_template_id > 0) {
            var option = new Option(sms_template.data('user-name'), sms_template_id, true, true)
            sms_template.append(option).trigger('change');
        }

    }

    teardown(){
        $(this.sms_templateTarget).select2('destroy');
    }

}
