import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "submit", "comments", "close", "status", "responsible", "reason", "responsibleCtr", "reasonCtr"];

  static values = {
    updateurl: String,
    cancelReasons: Object
  };

  connect() {

    this.responsibleCtrTarget.hidden = true
    this.reasonCtrTarget.hidden = true
    this.commentsTarget.hidden = true

    const cancelReasons = this.cancelReasonsValue

    $(this.modalTarget).modal({ dismissible: false });
    this.submitTarget.disabled = true;

    $(this.statusTarget).on("change", () => {
      if (this.statusTarget.value.trim().length > 0) {
        this.clearOptions(this.reasonTarget)
        if (this.statusTarget.value.trim() === 'request_cancellation') {
          this.responsibleCtrTarget.hidden = false
          this.reasonCtrTarget.hidden = true
          this.commentsTarget.hidden = true
          this.submitTarget.disabled = true;
        } else {
          this.responsibleCtrTarget.hidden = true
          this.reasonCtrTarget.hidden = true
          this.commentsTarget.hidden = false
          this.submitTarget.disabled = false;
        }

      } else {
        this.responsibleCtrTarget.hidden = true
        this.reasonCtrTarget.hidden = true
        this.commentsTarget.hidden = true
        this.submitTarget.disabled = true;
      }
    });

    $(this.responsibleTarget).on("change", () => {
      if (this.responsibleTarget.value.trim().length > 0) {
        this.clearOptions(this.reasonTarget)
        this.reasonCtrTarget.hidden = false

        const reasons = cancelReasons[this.responsibleTarget.value] || []
        const selectElem = $(this.reasonTarget)

        reasons.forEach(
            (res) => {
              const o = new Option(res, res, false, false);
              selectElem.append($(o));
            }
        );
        selectElem.material_select();


      } else {
        this.reasonCtrTarget.hidden = true
      }
    });

    $(this.reasonTarget).on("change", () => {
      if (this.reasonTarget.value.trim().length > 0) {
        this.commentsTarget.hidden = false
        this.submitTarget.disabled = false;
      } else {
        this.commentsTarget.hidden = true
        this.submitTarget.disabled = true;
      }
    });
  }

  submit() {
    this.submitTarget.disabled = true;
    this.closeTarget.disabled = true;

    const comment = this.commentsTarget.value;
    let cancelResponsible = null;
    let cancelReason = null;

    if (this.statusTarget.value.trim() === 'request_cancellation') {
      cancelResponsible = this.responsibleTarget.value.trim();
      cancelReason = this.reasonTarget.value.trim();
    }


    fetch(this.updateurlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        status: this.statusTarget.value,
        comment,
        cancelReason,
        cancelResponsible,
        source: "CONVERSION_WORKFLOW",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Unable to process the update, please contact support."
          );
        }
        return response.text();
      })
      .then((data) => {
        this.closeModal();
        location.reload();
      })
      .catch((e) => {
        alert("Error: " + e);
      })
      .finally(() => {
        this.submitTarget.disabled = false;
        this.closeTarget.disabled = false;
      });
  }

  closeModal() {
    $(this.modalTarget).modal("close");
  }

  clearOptions(target) {
    while (target.options.length) {
      target.remove(0);
    }
  }
}
