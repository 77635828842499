import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["add_new", "new_button"];

  connect() {
      console.log('ICD controller');
      const elem = $(this.add_newTarget);
      elem.hide();
      this.addNew = this.addNew.bind(this);
  }

  addNew() {
    console.log('add new');
    $(this.add_newTarget).show();
    $(this.new_buttonTarget).hide();
  }
}
