import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "output", "input_value", "select_value","select_value_ctr", "observation_type", "input_value_ctr" ]

  connect() {

    const objective_id = this.data.get("id")
    const objective_type = this.data.get("type")

    console.log("ID is " +  objective_id);
    console.log("Type is " +  objective_type);

    this.display_appropriate_field(objective_type);


    // Hack - data-action not triggering for materialize select
    $(this.observation_typeTarget).on('change', (e) => {
      this.observation_type_change();
    });
  }

  observation_type_change() {

    $(this.input_value_ctrTarget).hide();
    $(this.select_value_ctrTarget).hide();

    const observation_type_id = $(this.observation_typeTarget).val();

    const select = $(this.select_valueTarget);
    select.empty();

    fetch('/treatment_observation_types/' + observation_type_id + ".json", {
      method: 'GET',
      credentials: "same-origin"
    }).then((response) => response.json())
        .then((json) => {
          const {choices, measurement_type} = json;

          this.display_appropriate_field(measurement_type)

          if (measurement_type == 'choice') {

            choices.forEach((option) => {
              const o = new Option(option.value, option.id);
              select.append($(o))
            });


            select.material_select();

          }
        }).catch((err) => console.log(err)) ;


  }

  display_appropriate_field(objective_type) {

    if (objective_type == null) {
      $(this.input_value_ctrTarget).hide();
      $(this.select_value_ctrTarget).hide();
    }
    else if (objective_type === 'choice') {

      this.input_valueTarget.disabled=true;
      this.select_valueTarget.disabled=false;
      $(this.select_value_ctrTarget).show();
      $(this.input_value_ctrTarget).hide();

    }
    else {

      this.input_valueTarget.disabled=false;
      this.select_valueTarget.disabled=true;
      $(this.select_value_ctrTarget).hide();
      $(this.input_value_ctrTarget).show();

    }

  }
}
