import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="grid"
export default class extends Controller {

  connect() {

    console.log("Grid connected")

  }
}
