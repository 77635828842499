(function() {

    $(document).on('turbo:load', function() {
        $(".dropdown-button").dropdown();
        if ($('body')[0].id === 'sales') {
            return setTimeout((function() {
                $.ajax({
                    url: '/sales/current',
                    cache: false,
                    success: function(data) {
                        return console.log("Data broadcasted");
                    }
                });
            }), 10000);
        }
    });

}).call(this);
