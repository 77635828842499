import { Controller } from "@hotwired/stimulus";

import KeyAccountPayoutController from "./key_account_payout_controller.js"
export default class extends KeyAccountPayoutController {

    getPayload(paymentDate, transactionId, notes){
        return JSON.stringify({
            doctor_payout: {
                payment_date: paymentDate,
                transaction_id: transactionId,
                doctor_payout_notes_attributes: [{
                    notes,
                    action: this.actionValue,
                }]
            }
        })
    }

    setGiveVoucherAction() {
        this.setAction('give_voucher', 'Give Voucher')
    }

    setRegenerateAction(){
        this.setAction('regenerate_invoice', 'Regenerate Invoice')
    }
}