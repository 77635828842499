import { Controller } from "@hotwired/stimulus";
export default class extends Controller {

    static targets = ['status', 'responsible', 'reason', 'note'];
    static values = {
        reasons: Object
    };


    connect() {
        console.log("Loaded Bulk Appointment Status Update Item Controller.");
        this.disableStatusDependentTargets();

        $(this.statusTarget).on('change', () => {
            if($(this.statusTarget).val() === 'cancelled'){
                this.cancelledStatusHandler();
            }else if($(this.statusTarget).val() === 'successful'){
                this.successStatusHandler();
            }
        });

        $(this.responsibleTarget).on('change', () => {
            this.responsibleChangeHandler();
        });

        $(this.reasonTarget).on('change', () => {
            this.reasonChangeHandler();
        });
    }

    disableStatusDependentTargets(){
        this.clearResponsible(true, true);
        this.clearReason(true, true);
        this.clearNotes(false);
    }

    successStatusHandler(){
        this.clearResponsible(true, false);
        this.clearReason(true, false);
        this.clearNotes(false);
    }

    cancelledStatusHandler(){
        this.clearResponsible(false, true);
        this.populateResponsible();
        this.clearReason(true, true);
        this.clearNotes(true);
    }

    clearNotes(show){
        const note = $(this.noteTarget);
        note.val('');
        if(show === true) {
            note.show();
        }else{
            note.hide();
        }
    }

    clearReason(disable, show){
        this.clearSelectElement($(this.reasonTarget), disable, show);
    }

    clearResponsible(disable, show){
        this.clearSelectElement($(this.responsibleTarget), disable, show);
    }

    clearSelectElement(elem, disable, show){
        elem.find('input,select').empty();
        elem.find('input,select').prop('disabled', disable);
        elem.find($('select')).material_select();
        if(show === true){
            elem.show();
        }else{
            elem.hide();
        }
    }

    populateResponsible() {
        this.populateDropdownData($(this.responsibleTarget), Object.keys(this.reasonsValue));
    }

    responsibleChangeHandler(){
        this.clearReason(false, true);
        this.clearNotes(true);
        this.populateCancellationReason();
    }

    populateCancellationReason() {
        const responsible = $(this.responsibleTarget).find($('select')).val();

        if (responsible === '') {
            alert('Kindly select responsible for cancellation.');
        }
        else {
            this.populateDropdownData($(this.reasonTarget),
                this.reasonsValue[responsible]);
        }
    }

    reasonChangeHandler(){
        this.clearNotes(true);
    }

    populateDropdownData(elem, values){
        const select_elem = elem.find($('select'));
        const o = new Option('', '', true, true);
        select_elem.append($(o));

        values.forEach(
            (res) => {
                const o = new Option(res, res, false, false);
                select_elem.append($(o));
            }
        );
        select_elem.material_select();
    }
}