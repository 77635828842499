import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["caregiver_contact"];
    connect() {
        $(this.caregiver_contactTarget).on('blur', (e) => {
            this.handleCaregiverChange();
        });
    }
    handleCaregiverChange() {
        const caregiver_contact = $(this.caregiver_contactTarget).val();
        if(caregiver_contact.length == 0) {
            return true;
        }
        if(caregiver_contact.length < 10) {
            alert(" Please enter a valid 10 digit mobile number");
            return false;
        }
        var phoneno = /^[6-9]\d{9}$/;
        if(!caregiver_contact.match(phoneno)){
            alert(" Please enter a valid 10 digit mobile number");
            return false;
        }
    }
}