import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["approve", "modal", "submit", "notes", "close"];

  static values = {
    url: String,
    action: { type: String, default: "" },
  };

  connect() {
    console.log("Prospect Controller");

    $(this.modalTarget).modal({ dismissible: false });
    this.submitTarget.disabled = true;
    this.notesTarget.addEventListener("input", () => {
      if (this.notesTarget.value.trim().length > 0) {
        this.submitTarget.disabled = false;
      } else {
        this.submitTarget.disabled = true;
      }
    });
  }

  submit() {
    this.submitTarget.disabled = true;
    this.closeTarget.disabled = true;

    const notes = this.notesTarget.value;

    fetch(`${this.urlValue}?event=${this.actionValue}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        prospect: {
          prospect_notes_attributes: [
            {
              notes,
              action: this.actionValue,
            },
          ],
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Unable to process the action, please contact support."
          );
        }
        return response.json();
      })
      .then((data) => {
        this.closeModal();
        location.reload();
      })
      .catch((e) => {
        alert("Error: " + e);
      })
      .finally(() => {
        this.submitTarget.disabled = false;
        this.closeTarget.disabled = false;
      });
  }

  closeModal() {
    $(this.modalTarget).modal("close");
  }

  startAction() {
    this.actionValue = "start";
  }

  setupMeetingAction() {
    this.actionValue = "setup_meeting";
  }

  meetAction() {
    this.actionValue = "meet";
  }

  continueWorkAction() {
    this.actionValue = "continue_work";
  }

  requestSupportAction() {
    this.actionValue = "request_support";
  }

  resolveAction() {
    this.actionValue = "resolve";
  }

  sendToSignAction() {
    this.actionValue = "send_to_sign";
  }

  signMouAction() {
    this.actionvalue = "sign_mou";
  }

  markConvertedAction() {
    this.actionValue = "mark_converted";
  }

  cancelAction() {
    this.actionValue = "cancel";
  }
}
