import { Controller } from "@hotwired/stimulus";
import {TabulatorFull as Tabulator, DownloadModule} from 'tabulator-tables';


export default class extends Controller {

    static targets = ["info", "filterField", "filterType", "filterValue"]

    static values = {items: Array}

    connect() {

        super.connect();
        this.tableLoaded = false


        this.table = new Tabulator(this.infoTarget, {
            height:"600px",
            data: this.itemsValue,
            layout: "fitData",
            columns: [
                {title: 'Name', field:"patientLink", formatter:"link", formatterParams:{
                        labelField:"patientName",
                        target:"_blank",
                        rel: "noopener noreferrer"
                    }, download: false},
                {title: 'Workflow', field:"workflowLink", formatter:"link", formatterParams:{
                        labelField:"serviceName",
                        target:"_blank",
                        rel: "noopener noreferrer"
                    }, download: false},
                {title:"Patient Name", field:"patientName", visible: false, download: true},
                {title:"Patient ID", field:"patientId", visible: false, download: true},
                {title:"Conversion", field:"conversionStatus"},
                {title:"ETA", field:"appointmentTimeStr"},
                {title:"Assigned", field:"assignedTo"},
                {title:"Updated By", field:"lastUpdateBy"},
                {title:"Latest Update", field:"lastUpdate", width: 500, tooltip:true},
                {title:"Updated On", field:"lastUpdateOn"},
                {title:"Appt From", field:"appt_from"},
                {title:"City", field:"cityName"},
                {title:"Appointment ID", field:"appointmentId", visible: false, download: true},
                {title:"Ref Source", field:"referrerSource"},
                {title:"Service", field:"serviceName", visible: false, download: true},

            ],
        })

        this.table.on("tableBuilt", () => {
            this.tableLoaded = true
        })

    }

    download() {
        this.table.download('csv', 'data.csv')
    }


    clearFilter() {
        this.filterFieldTarget.selectedIndex = 0;
        this.filterTypeTarget.value = "=";
        this.filterValueTarget.value = "";
        this.table.clearFilter();
    }

    applyFilter() {

        let filterField = this.filterFieldTarget.options[this.filterFieldTarget.selectedIndex].value

            this.table.setFilter(filterField,
                this.filterTypeTarget.options[this.filterTypeTarget.selectedIndex].value,
                this.filterValueTarget.value
            )

    }


}