import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["verified_by"];

    connect() {
        console.log("Add document loaded");

        var verifyBy = $(this.verified_byTarget);

        verifyBy.sm_select({
            ajax: {
                url: () => verifyBy.data('url'),
                dataType: 'json',
                delay: 400,
            }
        });

        $(this.verified_byTarget).on('change', (e) => {

            var verifyById = verifyBy.data('user-id');

            if (verifyById > 0) {
                var option = new Option(verifyBy.data('user-name'), verifyById, true, true)
                verifyBy.append(option).trigger('change');

            }
        });


    }


}
