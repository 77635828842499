$(document).on('ready turbo:load turbo:render', function() {

    $('.main-menu-item').click(function() {

        var parentMenu = $(this).closest('ul').closest('li');
        var next = $(this).data('menu');
        var nextMenu = $('.sub-menu' + '.' + next);

        flipMenus(parentMenu, nextMenu);

    });

    $('.back').click(function () {

        var menu = $(this).closest('li');
        var prev = $(this).data('prev');
        var parentMenu = $('.sub-menu' + '.' + prev);

        flipMenus(menu, parentMenu);
    })

    selectCurrentContext();

});

function selectCurrentContext() {
    if (typeof menu_items === 'undefined'){
        return;
    }

    const currentItem = menu_items.find(m => m.menu_items.find(mi => mi.path == window.location.pathname));

    if (currentItem != null) {
        if (currentItem.prev != null) {
            const currentParentItem = menu_items.find(m => m.code == currentItem.prev);

            if (currentParentItem != null) {
                $('.menu_' + currentParentItem.code).addClass('active').click();
                $('ul.menu > li.menu_' + currentParentItem.code + '_' + currentItem.code + ' >  a').addClass('active').click()
            }

        }
        else {
            $('.menu_' + currentItem.code).addClass('active').click();
        }
    }
}

function flipMenus(currentMenu, nextMenu) {
    /*currentMenu.addClass('animated fadeOut faster');

    currentMenu.one('animationend', function _main() {

        currentMenu.addClass('hidden');

        nextMenu.removeClass('hidden');
        nextMenu.addClass('animated fadeIn faster');
        nextMenu.one('animationend', function () {
            nextMenu.removeClass('animated fadeIn faster');
        })

        currentMenu.removeClass('animated fadeOut faster');

    });*/

    currentMenu.addClass('hidden');
    nextMenu.removeClass('hidden');
}

function hideAllSubMenus() {

    var subMenus = document.querySelectorAll('.sub-menu');

    subMenus.forEach(function (subMenu) {
        subMenu.classList.add('hidden');

    });

}

document.addEventListener("turbo:before-cache", function() {
    hideAllSubMenus();
    document.querySelector('.sub-menu.main').classList.remove('hidden');
})