import { Controller } from "@hotwired/stimulus"
import DoctorPayoutController from "./doctor_payout_controller";

// Connects to data-controller="consultant-payout"
export default class extends DoctorPayoutController {
  getPayload(paymentDate, transactionId, notes){
    return JSON.stringify({
      consultant_payout: {
        payment_date: paymentDate,
        transaction_id: transactionId,
        consultant_payout_notes_attributes: [{
          notes,
          action: this.actionValue,
        }]
      }
    })
  }

}
