import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static values = {
        args: Array,
        url: String,
        argname: String,
    }

    connect() {
        this.setup();
    }

    setup() {
        var urlQueryParams = new URLSearchParams();
        if ((this.argnameValue != null) && (this.argnameValue !== '')) {
            this.argsValue.forEach(l => urlQueryParams.append(this.argnameValue, l) );
        }


        let url = this.urlValue;
        if(urlQueryParams.toString() !== ''){
            url = `${url}?${urlQueryParams.toString()}`
        }
        $(this.element).select2({
            placeholder: 'Select',
            allowClear: true,
            ajax: {
                url: () => url,
                cache: false,
                dataType: 'json',
                processResults: function (data) {
                    // Transforms the top-level key of the response object from 'items' to 'results'
                    return {
                        results: data.results
                    };
                }

            }
        });
    }

    teardown(){
        $(this.element).select2('destroy');
    }
}