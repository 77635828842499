import { Controller } from "@hotwired/stimulus";
import ActionCable from "actioncable-modules";

export default class extends Controller {
  static targets = ["loadProgress"];

  connect() {
    this.setupSubcription();
  }

  setupSubcription() {
    const cable = ActionCable.createConsumer();
    const bulkLoadId = this.data.get("id");
    const loadProgress = $(this.loadProgressTarget);

    cable.subscriptions.create(
      { channel: "BulkUploadChannel", bulk_upload_id: bulkLoadId },
      {
        received: function (data) {
          if (data.status == 'complete') {
            window.location.reload();
            return;
          }
          const progress = data.progress;
          loadProgress.css("width", `${progress}%`);
        },
      }
    );
  }
}
