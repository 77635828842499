import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts";

// Connects to data-controller="radar-chart"
export default class extends Controller {
  static values = { series: Array, categories: Array, title: String, stroke: Boolean, name: String };
  connect() {

    const options = {
      series: [{
        name: 'Percent Achieved',
        data: this.seriesValue,
      }],
      chart: {
        height: 350,
        type: 'radar',
      },
      dataLabels: {
        enabled: true
      },
      plotOptions: {
        radar: {
          size: 140,
          polygons: {
            strokeColors: '#e9e9e9',
            fill: {
              colors: ['#f8f8f8', '#fff']
            }
          }
        }
      },
      colors: ['#FF4560'],
      markers: {
        size: 4,
        hover: {
          size: 10
        },
        colors: ['#fff'],
        strokeColor: '#FF4560',
        strokeWidth: 1,
      },
      title: {
        text: this.titleValue
      },
      xaxis: {
        categories: this.categoriesValue,
        labels: {
          show: true,
          style: {
            colors: ["#1c1c1c", "#1c1c1c", "#1c1c1c", "#1c1c1c", "#1c1c1c", "#1c1c1c", "#1c1c1c"],
          }
        }
      }
    };

    const chart = new ApexCharts(this.element, options);
    chart.render();

  }
}
