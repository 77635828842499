import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ["modal"];

  static values = {
    url: String,
    action: { type: String, default: "" },
  };

  connect() {
    console.log("Modal Controller connected")
    $(this.modalTarget).modal({
      dismissible: false
    });
  }
  showModal() {
    $(this.modalTarget).modal("open");
  }

  closeModal() {
    $(this.modalTarget).modal("close");
  }

  setupMaterialize(event) {
    window.materializeForm.init();
    Materialize.updateTextFields();
  }
  formSubmitted(event) {

    if (
      event &&
      event.detail &&
      event.detail.formSubmission &&
      event.detail.formSubmission.result &&
      event.detail.formSubmission.result.success
    ) {
      this.closeModal();
      location.reload()
    }
  }
}
