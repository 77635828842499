import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["callback", "resolution"];

    connect() {

        // Initially callback should be hidden
        this.handleResolutionChange();

        $(this.resolutionTarget).on('change', (e) => {
            this.handleResolutionChange();
        });
    }

    handleResolutionChange() {

        const resolution = $(this.resolutionTarget).val();

        const elem = $(this.callbackTarget);

        if (resolution != 'callback') {
            elem.find('input,select').prop('disabled', true);

            elem.hide();
        } else {
            elem.find('input,select').prop('disabled', false);
            elem.find($('select')).material_select();

            elem.show();
        }
    }
}
