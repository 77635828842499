import { Controller } from "@hotwired/stimulus";
export default class extends Controller {

    static targets = [ 'map', 'slot' ]
    static values = {points: Array, initial: String, final: String}

    connect() {
        this.waitAndLoad()
    }

    setupMap() {
        const map = new (google.maps.Map)(this.mapTarget)
        const markers = this.displayPoints().map(p => ({lat: Number(p.latitude), lng: Number(p.longitude)}));
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < markers.length; i++) {
            bounds.extend(markers[i]);
        }

        map.fitBounds(bounds);

        this.displayPoints().forEach(m => new google.maps.Marker({
            position: {lat: Number(m.latitude), lng: Number(m.longitude)},
            map,
            title: this.getTitle(m),
        }))

        this.map = map;
    }

    displayPoints() {
        if ((this.initialValue == null) || (this.initialValue === '')) {
            return this.pointsValue;
        }

        const initialDate = new Date(this.initialValue)
        const finalDate = new Date(this.finalValue)
        return this.pointsValue.filter(p => {
            const recordedAt = new Date(p.timestamp)
            return ((recordedAt >= initialDate) && (recordedAt <= finalDate))
        })
    }

    waitAndLoad() {
        if (typeof google === 'undefined') {
            setTimeout(() => this.waitAndLoad(), 1000);
        } else {
            this.setupMap();
        }
    }

    focusActivity(event) {
        if (this.map) {
            this.map.panTo(new google.maps.LatLng(event.params.activity.latitude, event.params.activity.longitude));
        }

    }

    getTitle(point) {
        return point.activity_at
    }

}