import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["approve", "modal", "submit", "comments", "close", "eta", "department", "assignee",  "errors"];

    static values = {
        url: String,
        action: {type: String, default: ""},
    };

    errors = [];

    connect() {
        console.log("Service Ticket Controller");

        $(this.modalTarget).modal({dismissible: false});
        this.submitTarget.disabled = true;

        this.department = $(this.departmentTarget);
        this.eta = $(this.etaTarget);
        this.assignee = $(this.assigneeTarget);

        this.original_assignee = this.assignee.find('input,select').data('user-id');

        this.errors = $(this.errorsTarget);

        this.hideAll();

        this.commentsTarget.addEventListener("input", () => {
            if (this.commentsTarget.value.trim().length > 0) {
                this.submitTarget.disabled = false;
            } else {
                this.submitTarget.disabled = true;
            }
        });
    }

    submit() {
        // TODO - make sure all inputs are present
        this.submitTarget.disabled = true;
        this.closeTarget.disabled = true;

        this.clearErrors();

        const comments = this.commentsTarget.value;

        const eta = this.eta.find('input').val();
        const department = this.department.find('select').val();
        let assignee = this.assignee.find('select').val();
        
        if(this.assignee.find('input,select').prop('disabled') && assignee === null){
            assignee = this.original_assignee;
        }


        if(this.validate(eta, department, assignee)){
            this.showElement(this.errors);
            this.submitTarget.disabled = false;
            this.closeTarget.disabled = false;
            return
        }

        fetch(`${this.urlValue}?event=${this.actionValue}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                service_ticket: {
                    expected_resolution_date: eta,
                    current_department: department,
                    assignee_id: assignee,
                    service_ticket_comments_attributes: [
                        {
                            comments,
                            action: this.actionValue,
                        },
                    ],
                },
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        "Unable to process the action, please contact support."
                    );
                }
                return response.json();
            })
            .then((data) => {
                this.closeModal();
                location.reload();
            })
            .catch((e) => {
                alert("Error: " + e);
            })
            .finally(() => {
                this.submitTarget.disabled = false;
                this.closeTarget.disabled = false;
            });
    }

    closeModal() {
        $(this.modalTarget).modal("close");
    }

    startAction() {
        this.clearErrors();
        this.showAll();

        this.actionValue = "start";
    }

    workAction() {
        this.clearErrors();
        this.showElements([this.department, this.eta]);
        this.hideElements([this.assignee]);

        this.actionValue = "working";
    }

    closeAction() {
        this.clearErrors();
        this.hideAll();
        this.actionValue = "close";
    }

    cancelAction() {
        this.clearErrors();
        this.hideAll();
        this.actionValue = "cancel";
    }

    needApprovalAction() {
        this.clearErrors();
        this.showAll();
        this.actionValue = "need_approval";
    }

    approveAction() {
        this.clearErrors();
        this.hideAll();
        this.actionValue = 'approve'

    }

    needClarificationAction() {
        this.clearErrors();
        this.showOnlyAssignee();
        this.actionValue = 'need_clarification'
    }

    clarifyAction() {
        this.clearErrors();
        this.hideAll();
        this.actionValue = 'clarify'

    }

    escalateAction() {
        this.clearErrors();
        this.showOnlyAssignee();
        this.actionValue = 'escalate'
    }

    deescalateAction() {
        this.clearErrors();
        this.showOnlyAssignee();
        this.actionValue = 'deescalate'
    }

    resolveAction() {
        this.clearErrors();
        this.showOnlyDepartment();
        this.actionValue = 'resolve'
    }

    reopenAction() {
        this.clearErrors();
        this.hideAll();
        this.actionValue = 'reopen'
    }

    reAssignAction() {
        this.clearErrors();
        this.showOnlyAssignee();
        this.actionValue = 'reassign'
        this.assignee.find('input,select').empty();
    }

    hideAll() {
        this.hideElements([this.assignee, this.department, this.eta]);
    }

    showAll() {
        this.showElements([this.assignee, this.department, this.eta]);
    }

    showOnlyAssignee() {
        this.hideElements([this.department, this.eta]);
        this.showElements([this.assignee]);
    }

    showOnlyDepartment() {
        this.hideElements([this.assignee, this.eta]);
        this.showElements([this.department]);

    }

    hideElements(items) {
        items.forEach((option) => {
            this.hideElement(option);
        })
    }

    showElements(items) {
        items.forEach((option) => {
            this.showElement(option);
        })
    }

    hideElement(elem) {
        elem.find('input,select').prop('disabled', true);
        elem.hide();
    }

    showElement(elem) {
        elem.find('input,select').prop('disabled', false);
        elem.find($('select')).material_select();
        elem.show();
    }

    clearErrors() {
        this.errors[0].innerHTML = '';
        this.errors.hide();
    }

    validate(eta, department, assignee){
        this.errors.append('Please check below errors');

        let error = false;

        if(this.is_valid_eta(eta) !== true){
            this.errors.append('<li> ETA should be in future </li>');
            error = true;
        }

        if(this.is_valid_assignee(assignee) !== true){
            this.errors.append('<li> Assignee is mandatory </li>');
            error = true;
        }

        if(this.is_valid_department(department) !== true){
            this.errors.append('<li> Department is mandatory </li>');
            error = true;
        }

        return error;
    }

    is_valid_eta(eta){
        if(['cancel', 'close', 'resolve', 'reassign', 'reopen','escalate', 'deescalate', 'resolve_escalation', 'need_approval', 'approve', 'clarify', 'need_clarification' ].includes(this.actionValue)) {
            return true;
        }
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return eta !== '' && eta !== null && (new Date(eta)) > yesterday;
    }

    is_valid_assignee(assignee){
        if(this.actionValue == 'start'){
            return true;
        }
        return assignee !== '' && assignee !== null;
    }

    is_valid_department(department){
        if(['reassign', 'approve'].includes(this.actionValue)) {
            return true;
        }
        return department !== null && department !== '' ;
    }

}
