import { Controller } from "@hotwired/stimulus"
import {Modal} from 'tw-elements'
// Connects to data-controller="doctor-equipment-payment"
export default class extends Controller {
  static targets = ["approve", "modal", "payoutValue", "payoutType", "reject", "notes"];

  static values = {
    approveurl: String,
    rejecturl: String,
    payoutType: String,
    payoutValue: Number,
    action: { type: String, default: "approve" },
  };
  connect() {
    console.log("Doctor Equipment Payout connected")
  }

  approve() {
    this.actionValue = "approve";
    this.submit();
  }

  reject() {
    this.actionValue = "reject";
    this.submit();
  }

  submit() {
    this.approveTarget.disabled = true;
    this.rejectTarget.disabled = true;

    const url =
        this.actionValue === "approve"
            ? this.approveurlValue
            : this.rejecturlValue;


    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        doctor_equipment_payout: {
          payout_type: this.payoutTypeTarget.value,
          payout_value: this.payoutValueTarget.value,
          doctor_equipment_payout_notes_attributes: [{
            notes:  this.notesTarget.value,
            action: this.actionValue,
          }],
        },
      }),
    })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Unable to process the action, please contact support.');
          }
          return response.json();
        })
        .then((data) => {
          this.closeModal();
          location.reload();
        })
        .catch((e) => {
          alert("Error: " + e);
        }).finally(() => {
      this.approveTarget.disabled = false;
      this.rejectTarget.disabled = false;

    });

  }

  closeModal() {
    Modal.getInstance(this.modalTarget).hide()
  }

}
