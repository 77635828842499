import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ['save', 'resolution', 'remarks', 'done', 'govt_id', 'updated_number', 'error'];

    connect() {
        $(this.doneTarget).hide();
        $(this.errorTarget).hide();
        $(this.saveTarget).on('click', (e) => {
            this.saveVerifier();
        });
    }

    saveVerifier() {
        const save = $(this.saveTarget);
        const done = $(this.doneTarget);
        const show_error = $(this.errorTarget);
        const updated_number = $(this.updated_numberTarget).val();

        const resolution = $(this.resolutionTarget).val();
        const remarks = $(this.remarksTarget).val();
        if(resolution === '' ){
            alert('Please select resolution for Govt ID -' +  $(this.govt_idTarget).html());
        }else if(resolution === 'sharing_updated_number' && updated_number.length != 10 ){
            alert('Please enter 10 digit mobile number for Govt ID -' +  $(this.govt_idTarget).html());
        }else{
            $(save).hide();
            $(done).hide();
            fetch(this.data.get("treatment_id") + '/treatment_verifiers/' + this.data.get("id") + '.json', {
                method: 'PATCH',
                headers:  {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                credentials: 'same-origin',
                body:  JSON.stringify({'treatment_verifier' : {resolution: resolution, remarks: remarks, updated_number: updated_number, status: 'acknowledged' }}),

            }).then((response) => {
                if(response.status == 200){
                    $(done).show();
                }else{
                    console.log(response);
                    $(show_error).append("Error from server: " + response.statusText);
                    $(show_error).show();
                }
            }).catch((err) => {
                console.log(err);
                $(show_error).append("Error in contacting server: " + err);
                $(show_error).show();
            });
        }
    }

}