import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["approve", "modal", "submit", "notes", "close"];

  static values = {
    approveurl: String,
    rejecturl: String,
    action: { type: String, default: "approve" },
  };

  connect() {
    console.log("Medic Proposal Controller");
    console.log($(this.modalTarget));
    $(this.modalTarget).modal({dismissible: false,});
    console.log("Approve URL", this.approveurlValue);
    this.submitTarget.disabled = true;
    this.notesTarget.addEventListener("input", () => {
      if (this.notesTarget.value.trim().length > 0) {
        this.submitTarget.disabled = false;
      } else {
        this.submitTarget.disabled = true;
      }
    });
  }

  setApproveAction() {
    this.actionValue = "approve";
    this.submitTarget.innerText = "Approve";
  }

  setRejectAction() {
    this.actionValue = "reject";
    this.submitTarget.innerText = "Reject";
  }

  submit() {

    this.submitTarget.disabled = true;
    this.closeTarget.disabled = true;

    const url =
      this.actionValue === "approve"
        ? this.approveurlValue
        : this.rejecturlValue;
    const notes = this.notesTarget.value;

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        medic_proposal: {
          medic_proposal_notes_attributes: [{
            notes,
            action: this.actionValue,
          }],
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Unable to process the action, please contact support.');
        }
        return response.json();
      })
      .then((data) => {
        this.closeModal();
        location.reload();
      })
      .catch((e) => {
        alert("Error: " + e);
      }).finally(() => {
      this.submitTarget.disabled = false;
      this.closeTarget.disabled = false;

    });
  }

  closeModal() {
    $(this.modalTarget).modal("close");
  }
}
