import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

// Connects to data-controller="gauge-chart"
export default class extends Controller {
  static values = { series: Array, labels: Array, gradient: String, stroke: Boolean };
  connect() {
    console.log("Stroke; ", this.strokeValue)
    const options = {
      series: this.seriesValue,
      chart: {
        type: "radialBar",
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        colors: ['#E91E63' , '#46ab00', '#5A2A27', '#F86624', ],
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              offsetY: -30,
              fontSize: "26px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -30
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          gradientToColors: [this.gradientValue],
          shadeIntensity: 0.4,
          stops: [0, 30, 60, 100],
        },
      },

      labels: this.labelsValue,
    };

    if (this.strokeValue) {
      options['stroke'] ={
        dashArray: 4
      }
    }

    const chart = new ApexCharts(this.element, options);
    chart.render();
  }
}
