import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/flatpickr.css";
import "../common/flatpickr.css";

import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default class extends Flatpickr {

    initialize() {
        //global options
        this.config = {
            plugins: [
                new monthSelectPlugin({
                    shorthand: true,
                })
            ],
            onReady: function () {
                this.monthsDropdownContainer.classList.add('browser-default')
            }
        };
    }


}

