import { Controller } from "@hotwired/stimulus";

const Uppy = require('@uppy/core')
const XHRUpload = require('@uppy/xhr-upload')
const Dashboard = require('@uppy/dashboard')

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

export default class extends Controller {

    static targets = ["doctype", "dashboard", "content", "validTill", "validTillLabel"];
    static values = {
        validTillTypes: Array
    };

    connect() {
        console.log("Connecting")

        const docType = $(this.doctypeTarget);
        const validTill = $(this.validTillTarget);
        this.handleDocTypeChange();

        const uppy = new Uppy({
            autoProceed: true,
            restrictions: {
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
                maxFileSize: 2*1024*1024,
                allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.pdf']
            }
        }).use(Dashboard, {
            target: this.dashboardTarget,
            inline: true,
            width: '100%',
            height: '300px',
            showLinkToFileUploadResult: false,
            showProgressDetails: true,
            proudlyDisplayPoweredByUppy: false,
            doneButtonHandler: () => {
                uppy.reset()
                docType.val("")
                validTill.val("")
                validTill.trigger('change');
                docType.material_select();
                docType.trigger('change');
                $(document).trigger('content.reload')
            }

        }).use(XHRUpload, { endpoint: `${this.data.get("url")}.json` });

        const dashboard = uppy.getPlugin('Dashboard')
        dashboard.setOptions({ disabled: true })

        $(this.doctypeTarget).on('change', (e) => {
            this.handleDocTypeChange();
            this.handleDocUploadButton(dashboard);
            const docType = $(this.doctypeTarget).val();
            uppy.setMeta({document_type: docType})

        });

        $(this.validTillTarget).on('change', (e) => {
            uppy.setMeta({valid_till: $(this.validTillTarget).val()});
        });

        this.validTillTarget.addEventListener("input", () => {
            uppy.setMeta({valid_till: $(this.validTillTarget).val()});
            this.handleDocUploadButton(dashboard);
        });
    }

    handleDocTypeChange(){
        const docType = $(this.doctypeTarget).val();
        const validTill = $(this.validTillTarget);
        if(this.validTillTypesValue.includes(docType)){
            validTill.show();
            $(this.validTillLabelTarget).addClass('active');
        }else{
            validTill.val('');
            validTill.trigger('change');
            validTill.hide();
            $(this.validTillLabelTarget).removeClass('active');
        }
    }

    handleDocUploadButton(dashboard){
        const docType = $(this.doctypeTarget).val();
        const validTill = $(this.validTillTarget).val();
        const considerValidTill = this.validTillTypesValue.includes(docType);

        var disableUpload = docType == null || docType == '' ;
        if(considerValidTill === true){
            disableUpload = disableUpload || (validTill == null || validTill == '')
        }

        if (disableUpload) {
            dashboard.setOptions({ disabled: true })
        }
        else {
            dashboard.setOptions({ disabled: false })
        }
    }


}