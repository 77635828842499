import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["end_reason", "sub_reason_fields"];

    connect() {

        // Initially sub reason should be hidden
        this.handleEndReasonChange();

        $(this.end_reasonTarget).on('change', (e) => {
            this.handleEndReasonChange();
        });
    }

    handleEndReasonChange() {

        const end_reason = $(this.end_reasonTarget).val();

        const elem = $(this.sub_reason_fieldsTarget);

        if (end_reason != 'treatment_discontinued') {
            elem.find('input,select').prop('disabled', true);

            elem.hide();
        } else {
            elem.find('input,select').prop('disabled', false);
            elem.find($('select')).material_select();

            elem.show();
        }
    }
}
