import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.setup();
    }

    setup() {
        $(this.element).select2({placeholder: 'Select', allowClear: true})
    }

    teardown(){
        $(this.element).select2('destroy');
    }
}