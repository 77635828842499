import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["other_assignee_profession","other_assignee", "other_assignee_type"];


    connect() {

        var other_assignee = $(this.other_assigneeTarget);
        var other_assignee_type = $(this.other_assignee_typeTarget);
        other_assignee.sm_select({
            ajax: {
                url: () => other_assignee.data('url') + '/' + $(this.other_assignee_professionTarget).val(),
                dataType: 'json',
                delay: 400,
            }
        });

        var other_assignee_type_id = other_assignee_type.data('treatment-assignee-type-id');

        var other_assignee_id = other_assignee.data('user-id');

            if (other_assignee_id > 0) {
                var option = new Option(other_assignee.data('user-name'), other_assignee_id, true, true)
                other_assignee.append(option).trigger('change');

            }

    }

    teardown(){
        $(this.other_assigneeTarget).select2('destroy');
        $(this.other_assignee_typeTarget).select2('destroy');
    }

}
