import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["treatment_medicine", "sps_doctor", "pharmaco"];

    connect() {

        $(this.pharmacoTarget).on('change', (e) => {
            this.onPharmacoChange();
        });
        this.handleSearch($(this.sps_doctorTarget));
        this.handleSearch($(this.treatment_medicineTarget));
        this.onPharmacoChange();
    }

    handleSearch(search_elem){
        search_elem.sm_select({
            ajax: {
                url: () => {
                    console.log(this.pharmacoTarget.value)
                    const urlQueryParams = new URLSearchParams()
                    urlQueryParams.set('pharmaco_id', this.pharmacoTarget.value)
                    return `${search_elem.data('url')}?${urlQueryParams.toString()}`},
                dataType: 'json',
                delay: 400,
            }
        });

        var search_elem_id = search_elem.data('id');

        if (search_elem_id > 0) {
            var option = new Option(search_elem.data('name'), search_elem_id, true, true)
            search_elem.append(option).trigger('change');
        }

        $('select').material_select();

    }

    teardown(){
        $(this.sps_doctorTarget).select2('destroy');
        $(this.treatment_medicineTarget).select2('destroy');
    }

    onPharmacoChange(e) {
        if (this.pharmacoTarget.value != '') {
            this.sps_doctorTarget.disabled = false;
        } else {
            this.sps_doctorTarget.disabled = true;
        }


    }


}
