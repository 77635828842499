import { Controller } from "@hotwired/stimulus";
export default class extends Controller {

    static targets = ['area', 'serviceType', 'coordinator'];

    connect() {
        console.log("Loaded Bulk Appointment Status Update Request Controller.");
        if(!this.coordinatorTarget.disabled){
            this.populateCoordinators();
        }
        this.setupAreaChangeHandler();
        this.setupServiceTypeChangeHandler();
    }

    setupAreaChangeHandler(){
        $(this.areaTarget).on('change', () => {
            this.populateCoordinators();
        });
    }

    setupServiceTypeChangeHandler(){
        $(this.serviceTypeTarget).on('change', () => {
            const coordinatorElem = $(this.coordinatorTarget);
            coordinatorElem.empty();
            coordinatorElem.material_select();
            this.populateCoordinators();
        });
    }

    populateCoordinators() {
        const applicableServiceTypes = ['6', '11', '14'];
        const area = $(this.areaTarget).val();
        const serviceType = $(this.serviceTypeTarget).val();

        if(area !== '' &&  serviceType !== '' && applicableServiceTypes.includes(serviceType)){
            this.coordinatorTarget.disabled = false;
            fetch(`/health_professional_details/bulk_update_coordinators?area_id= ${area}.json`, {
                method: 'GET',
                credentials: "same-origin"
            }).then(
                (response) => response.json()
            ).then((json) => {
                this.populateDropdownData($(this.coordinatorTarget), json);
            }).catch((err) => {
                console.log(err);
                alert(`Error Encountered during bulk appointment status update coordinators API call: ${err.message}`);
            });
        }
    }

    populateDropdownData(elem, values){
        elem.empty();
        const o = new Option('', '', true, true);
        elem.append($(o));

       const selectedCoordinator = values.filter(this.checkLoggedInCoordinator);
       if(selectedCoordinator.length !== 0){
           elem.empty();
           const o = new Option(selectedCoordinator[0].name, selectedCoordinator[0].id, selectedCoordinator[0].selected, selectedCoordinator[0].selected);
           elem.append($(o));
           elem.material_select();
       }else{
           values.forEach(
               (option) => {
                   const o = new Option(option.name, option.id, option.selected, option.selected);
                   elem.append($(o));
               }
           );
           elem.material_select();
       }
    }

    checkLoggedInCoordinator(option){
        return option.selected;
    }
}