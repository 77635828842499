import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["assignee", "treatment_type", "assignee_profession", "pharma_fields", "covid_info_fields", "sub_type", "nerivio_info_fields"];

    connect() {

        console.log("treatment form loaded")
        this.handleTreatmentTypeChange();
        $(this.treatment_typeTarget).on('change', (e) => {
            this.handleTreatmentTypeChange();
        });
    }

    handleTreatmentTypeChange() {

        const treatmentType = $(this.treatment_typeTarget).val();
        const covidElem = $(this.covid_info_fieldsTarget);
        const nerivioInfoElem = $(this.nerivio_info_fieldsTarget);

        const elem = $(this.pharma_fieldsTarget);

        if (treatmentType == null || treatmentType == '') {
            this.hideFormFields(elem);
            this.hideFormFields(covidElem);
            this.hideFormFields(nerivioInfoElem);
            return
        }
        this.updateSubTypeValues($(this.sub_typeTarget), treatmentType);

        // TBD - Fetch actual attributes from server and then do this
        // Pharma treatment types are below 4. Change if that changes
        if (treatmentType >=  4) {
            this.hideFormFields(elem);
        }
        else {
            this.showFormFields(elem);
        }

        const treatment_type_elem_map = [
            { types: [9, 10, 11, 12, 13, 14, 15, 16, 17], infoElem: covidElem},
            {types: [26], infoElem: nerivioInfoElem}]

        treatment_type_elem_map.forEach((m) => {
            if (m.types.indexOf(parseInt(treatmentType)) !== -1) {
                this.showFormFields(m.infoElem);
            } else {
                this.hideFormFields(m.infoElem)
            }
        })
    }

    showFormFields(elem) {
        elem.find($('input')).prop('disabled', false);
        elem.find($('select')).prop('disabled', false);
        elem.find($('textarea')).prop('disabled', false);
        elem.show();

    }

    hideFormFields(elem) {
        elem.find($('input')).prop('disabled', true);
        elem.find($('select')).prop('disabled', true);
        elem.find($('textarea')).prop('disabled', true);

        elem.hide();
    }

    updateSubTypeValues(sub_type_elem, treatmentType) {
        fetch('/treatments/treatment_sub_types/' + treatmentType + ".json", {
            method: 'GET',
            credentials: "same-origin"
        }).then(
            (response) => response.json()
        ).then((json) => {
            const selected_value = sub_type_elem.val();
            sub_type_elem.empty();
            const {sub_types} = json;
            sub_types.forEach((option) => {
                const selected = option === selected_value ? true : false
                const o = new Option(option.toLowerCase().split('_')
                    .map(function(s) {return (s.charAt(0).toUpperCase() + s.slice(1));})
                    .join(' '), option, selected, selected);
                sub_type_elem.append($(o))
            });
            sub_type_elem.material_select();
        }).catch((err) => console.log(err)) ;
    }
}
