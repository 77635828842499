import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["send_immediately", "occurrence_limit",
        "has_sms", "sms_fields", "has_email", "email_fields",
        "has_whatsapp", "whatsapp_fields", "schedule_fields", "manual_schedule"];

    connect() {
        console.log("communication type event loaded");

        this.handleSendImmediatelyChange();
        this.handleHasSmsChange();
        this.handleHasEmailChange();
        this.handleHasWhatsappChange();

        $('select').on('change', function() {
            $(this).material_select();
        });

        $(this.send_immediatelyTarget).on('change', (e) => {
            this.handleSendImmediatelyChange();
        });

        $(this.has_smsTarget).on('change', (e) => {
            this.handleHasSmsChange();
        });

        $(this.has_emailTarget).on('change', (e) => {
            this.handleHasEmailChange();
        });

        $(this.has_whatsappTarget).on('change', (e) => {
            this.handleHasWhatsappChange();
        });

    }

    handleSendImmediatelyChange(){
        const send_imm = $(this.send_immediatelyTarget)[0].checked;
        const scheduleFields = $(this.schedule_fieldsTarget);
        const manualSchedule = $(this.manual_scheduleTarget);
        const occField = $(this.occurrence_limitTarget);

        if( send_imm ){
            scheduleFields.find('input').prop('disabled', true);
            scheduleFields.find('select').prop('disabled', true);

            manualSchedule.prop('disabled', true);

            occField.prop('readonly', true);
            occField.val(1);
        }else{
            scheduleFields.find('input').prop('disabled', false);
            scheduleFields.find('select').prop('disabled', false);

            manualSchedule.prop('disabled', false);

            occField.prop('readonly', false);
        }
    }

    handleHasSmsChange(){
        const has_sms = $(this.has_smsTarget)[0].checked;
        const scheduleSmsFields = $(this.sms_fieldsTarget);

        if( has_sms ){
            scheduleSmsFields.find('input').prop('disabled', false);
            scheduleSmsFields.find('select').prop('disabled', false);
            scheduleSmsFields.show();
        }else{
            scheduleSmsFields.find('input').prop('disabled', true);
            scheduleSmsFields.find('select').prop('disabled', true);
            scheduleSmsFields.hide();
        }
    }

    handleHasWhatsappChange() {
        const has_whatsapp = $(this.has_whatsappTarget)[0].checked;
        const scheduleWhatsappFields = $(this.whatsapp_fieldsTarget);

        if( has_whatsapp ){
            scheduleWhatsappFields.find('input').prop('disabled', false);
            scheduleWhatsappFields.find('select').prop('disabled', false);
            scheduleWhatsappFields.show();
        }else{
            scheduleWhatsappFields.find('input').prop('disabled', true);
            scheduleWhatsappFields.find('select').prop('disabled', true);
            scheduleWhatsappFields.hide();
        }
    }

    handleHasEmailChange(){
        const has_email = $(this.has_emailTarget)[0].checked;
        const scheduleEmailFields = $(this.email_fieldsTarget);

        if( has_email ){
            scheduleEmailFields.find('input').prop('disabled', false);
            scheduleEmailFields.show();
        }else{
            scheduleEmailFields.find('input').prop('disabled', true);
            scheduleEmailFields.hide();
        }
    }

}
