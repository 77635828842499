import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["company", "ticket", "patient_package_mapping", "amount"];

    connect() {
        console.log("Payment Request Controller");

        this.handleCompanyChange(false);
        // TODO: Prevent company change when pre-provided
        $(this.companyTarget).on('change', (e) => {
            this.handleCompanyChange(true);
        });

        $(this.ticketTarget).on('change', (e) => {
            this.changeAmount($(this.ticketTarget).find($('select')).val());
        });
    }

    handleCompanyChange(called_on_change)
    { 
        const company =$(this.companyTarget).val();

        if (company == 'HVPL') {
            $(this.ticketTarget).find($('select')).prop('disabled', true);
            $(this.ticketTarget).hide();
            $(this.patient_package_mappingTarget).find($('select')).prop('disabled', false);
            $(this.patient_package_mappingTarget).show();

            if((!called_on_change && $(this.amountTarget).val() == '' ) || called_on_change){
                $(this.amountTarget).val(0);
            }

        }
        else {
            $(this.patient_package_mappingTarget).find($('select')).prop('disabled', true);
            $(this.patient_package_mappingTarget).hide();
            $(this.ticketTarget).find($('select')).prop('disabled', false);
            $(this.ticketTarget).show();
            this.loadTickets($(this.ticketTarget))
            if((!called_on_change && $(this.amountTarget).val() == '' ) || called_on_change){
                this.changeAmount($(this.ticketTarget).find($('select')).val());
            }

        }
        $('select').material_select();
    }

    loadTickets(ticket_elem){
        const select_ticket_elem = ticket_elem.find($('select'))
        const ticket_id = select_ticket_elem.val()
        let ticketsForPaymentUrl = '/tickets/for_payment/' + ticket_elem.data('patient') + '/' + $(this.companyTarget).val();

        if (ticket_elem.data('provided-ticket-id')) {
            ticketsForPaymentUrl = ticketsForPaymentUrl + '/' + ticket_elem.data('provided-ticket-id')
        }
        fetch( `${ticketsForPaymentUrl}.json`, {
            method: 'GET',
            credentials: "same-origin"
        }).then(
            (response) => response.json()
        ).then((json) => {
            select_ticket_elem.empty();
            const {tickets} = json;
            select_ticket_elem.append($(new Option('', '', false, false)))
            tickets.forEach((option) => {
                const selected = option.id == ticket_id
                const o = new Option(option.name, option.id, selected, selected);
                select_ticket_elem.append($(o))
            });
            select_ticket_elem.material_select();
        }).catch((err) => {
            console.log(err);
            alert(err);
        }) ;
    }

    changeAmount(ticket_id){

        if(ticket_id != '' && ticket_id != null) {
            fetch('/tickets/' + ticket_id + "/order_amount", {
                method: 'GET',
                credentials: "same-origin"
            }).then((response) => response.json())
            .then((json) => {
                    console.log(json);

                $(this.amountTarget).val(json.amount);
            }).catch((err) => console.log(err)) ;
        }



    }
}
