import { Controller } from "@hotwired/stimulus";
import BdoActivitiesController from "./bdo_activities_controller.js"
export default class extends BdoActivitiesController {
    getTitle(point) {
        return point.recorded_at
    }

    setPointsFilter({params, currentTarget}) {
        console.log(params)
        const initial = new Date(params.initial);
        const final = new Date(params.final);

        this.handleSlotClick(currentTarget, initial, final)
    }

    resetPointsFilter({currentTarget}) {
        this.handleSlotClick(currentTarget, null, null)
    }

    handleSlotClick(currentTarget, initial, final) {
        this.removeActiveClass()
        currentTarget.classList.add('font-bold')
        this.initialValue = initial;
        this.finalValue = final;
        this.setupMap()
    }

    removeActiveClass() {
        this.slotTargets.forEach(t => t.classList.remove('font-bold'))
    }

}