import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "submit", "notes", "close"];
  static values = {
      url: String
  };

  connect() {
    console.log("BDO Doctor submission Controller");

    $(this.modalTarget).modal({ dismissible: false });
    this.submitTarget.disabled = true;
    this.notesTarget.addEventListener("input", () => {
      if (this.notesTarget.value.trim().length > 0) {
        this.submitTarget.disabled = false;
      } else {
        this.submitTarget.disabled = true;
      }
    });
  }

  submit() {
    this.submitTarget.disabled = true;
    this.closeTarget.disabled = true;

    const notes = this.notesTarget.value;

    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        bdo_doctor_submission: {
            bdo_doctor_submission_notes_attributes: [
            {
              notes,
              action: 'update',
            },
          ],
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Unable to process the action, please contact support."
          );
        }
        return response.json();
      })
      .then((data) => {
        this.closeModal();
        location.reload();
      })
      .catch((e) => {
        alert("Error: " + e);
      })
      .finally(() => {
        this.submitTarget.disabled = false;
        this.closeTarget.disabled = false;
      });
  }

  closeModal() {
    $(this.modalTarget).modal("close");
  }

  addComment(){
  }

}
