import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["item", "item_detail"];

    connect() {
        console.log("Loaded Invoice Detail Controller");
    }

    process(event){
        this.disableHighlighted();
        this.highlightSelected(event.params.id);
        this.fetchItem(event);
    }

    disableHighlighted(){
        $(this.itemTargets).each(
            (k, elem) => {
                elem.classList.remove('highlight_row');
            });
    }

    highlightSelected(selected_item_id){
        $(this.itemTargets).each(
            (k, elem) => {
                console.log(parseInt(this.itemId(elem)));
                if(parseInt(this.itemId(elem)) === selected_item_id){
                    elem.classList.add('highlight_row');
                }
            });
    }

    fetchItem(event){
        fetch(event.params.url)
            .then(response => response.text())
            .then(html => {
                this.item_detailTarget.innerHTML = html
            })
    }

    itemId(elem){
        return elem.getAttribute("data-invoice-detail-id-param")
    }
}