import { Controller } from "@hotwired/stimulus";

export default class extends Controller {


    static targets = [ "item_list", "modal", "submit", "continue"];
    errors = [];

    connect() {
        console.log("items loaded");

        $(this.modalTarget).modal({
            dismissible: true
        });

        $(this.submitTarget).on('click', (e) => {
            this.validate();
            if(this.errors.length > 0){
                e.preventDefault();
                this.confirmWarning();
            }
        });

        $(this.continueTarget).on('click', (e) => {
            $(this.submitTarget).submit();
        });
    }

    validate(){
        this.clearErrors();

        $(this.item_listTargets).each(
            (k, elem) => {
                if(elem.dataset.controller != undefined){
                    const itemController = this.application.getControllerForElementAndIdentifier(
                        elem,
                        elem.dataset.controller
                    );
                    this.errors = this.errors.concat(itemController.validate());
                }else{
                    console.log('No controller defined for child element')
                }

            }
        );
    }

    clearErrors(){
        this.errors = [];
        $(".modal-body").empty();
    }

    confirmWarning(){
        this.errors.forEach((value) =>{
            $(".modal-body").append('<li>'+ value +'</li>');
        });
        $(this.modalTarget).modal('open');
    }
}
