import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = [ 'tab' ]
  static values = { initialFrame: String };
  connect() {
    this.currentFrameId = this.initialFrameValue;
  }

  toggle(event) {
    this.tabTargets.forEach(i => i.removeAttribute("data-pt-nav-active"))
    event.currentTarget.dataset.ptNavActive = true;

    const frameId = event.currentTarget.dataset.turboFrame;


    if (frameId === this.currentFrameId) return;

    // Hide the currently active frame
    document.getElementById(this.currentFrameId).classList.add("hidden");

    // Show the newly selected frame
    document.getElementById(frameId).classList.remove("hidden");

    // Update the current frame ID
    this.currentFrameId = frameId;
  }
}
