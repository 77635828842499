import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["package", "program", "sub_program"];

    connect() {

        this.handleProgramChange();
        $(this.programTarget).on('change', (e) => {
            this.handleProgramChange();
        });
    }

    handleProgramChange() {

        const program = $(this.programTarget).val();
        const elem = $(this.packageTarget);
        const sub_program_elem = $(this.sub_programTarget);

        if (program == null || program == '') {
            sub_program_elem.empty();
            sub_program_elem.material_select();
            this.hideFormFields(elem);
            return
        }

        this.updateSubProgramValues(sub_program_elem, program);

        if (program == 'home_isolation' || program == 'insurance') {
            this.showFormFields(elem);
        }
        else {
            this.hideFormFields(elem);
        }
    }

    showFormFields(elem) {
        elem.find($('input')).prop('disabled', false);
        elem.find($('select')).prop('disabled', false);
        elem.find($('textarea')).prop('disabled', false);
        elem.find($('select')).material_select();
        elem.show();
    }

    hideFormFields(elem) {
        elem.find($('input')).prop('disabled', true);
        elem.find($('select')).prop('disabled', true);
        elem.find($('textarea')).prop('disabled', true);

        elem.hide();
    }

    updateSubProgramValues(sub_program_elem, program) {
        const subprogramList = $(this.programTarget).data('programList')[program];
        const selected_value = sub_program_elem.val();
        sub_program_elem.empty();
        subprogramList.forEach((option) => {
            const selected = option === selected_value ? true : false
            const o = new Option(option.toLowerCase().split('_')
                .map(function(s) {return (s.charAt(0).toUpperCase() + s.slice(1));})
                .join(' '), option, selected, selected);
            sub_program_elem.append($(o))
        });
        sub_program_elem.material_select();
    }
}
