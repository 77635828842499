import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["communication_type"];

    connect() {
        console.log("communication type event loaded");

        var communication_type = $(this.communication_typeTarget);

        communication_type.sm_select({
            ajax: {
                url: () => communication_type.data('url'),
                dataType: 'json',
                delay: 400,
            }
        });

        var communication_type_id = communication_type.data('user-id');

        if (communication_type_id > 0) {
            var option = new Option(communication_type.data('user-name'), communication_type_id, true, true)
            communication_type.append(option).trigger('change');
        }

    }

    teardown(){
        $(this.communication_typeTarget).select2('destroy');
    }


}
